<template>
  <div>
    <a-card class="container">
      <div class="card" v-if="$getPermission($route.path + '/boss')">
        <div class="item" :class="allocateType === 'monthPlan' ? 'active' : ''">
          <div class="title">
            <!-- <a-checkbox
              :checked="allocateType === 'monthPlan'"
              @change="e => onConditionChange('monthPlan',e.target.checked)"
            >当月计划确认(万)</a-checkbox>-->
            当月计划确认(万)
            <a-tooltip placement="top" title="本年度当月实时进度计划总额">
              <a-icon type="question-circle" style="margin-left: 5px;"/>
            </a-tooltip>
          </div>
          <div
            class="value"
          >{{ typeof statistic.planMonthSum === 'number' ? statistic.planMonthSum.toLocaleString() : '--' }}</div>
        </div>
        <div class="item" :class="allocateType === 'monthApproved' ? 'active' : ''">
          <div class="title">
            <a-checkbox
              :checked="allocateType === 'monthApproved'"
              @change="e => onConditionChange('monthApproved', e.target.checked)"
            >当月已确认(万)</a-checkbox>
            <a-tooltip placement="top" title="本年度当月实时进度确认总额">
              <a-icon type="question-circle"/>
            </a-tooltip>
          </div>
          <div
            class="value"
          >{{ typeof statistic.approvedMonthSum === 'number' ? statistic.approvedMonthSum.toLocaleString() : '--' }}</div>
        </div>
        <div class="item" :class="allocateType === 'yearApproved' ? 'active' : ''">
          <div class="title">
            <a-checkbox
              :checked="allocateType === 'yearApproved'"
              @change="e => onConditionChange('yearApproved', e.target.checked)"
            >当年已确认(万)</a-checkbox>
            <a-tooltip placement="top" title="本年度进度确认总额">
              <a-icon type="question-circle"/>
            </a-tooltip>
            <span class="extra">
              <MonthList type="dept" @search="onSearch" />
            </span>
          </div>
          <div
            class="value"
          >{{ typeof statistic.approvedYearSum === 'number' ? statistic.approvedYearSum.toLocaleString() : '--' }}</div>
        </div>
        <div class="item" :class="allocateType === 'notProgressConfigured' ? 'active' : ''">
          <div class="title">
            <a-checkbox
              :checked="allocateType === 'notProgressConfigured'"
              @change="e => onConditionChange('notProgressConfigured', e.target.checked)"
            >当年已确认未分配(万)</a-checkbox>
            <a-tooltip placement="top" title="本年度进度确认未完成人员分配总额">
              <a-icon type="question-circle"/>
            </a-tooltip>
          </div>
          <div
            class="value"
          >{{ typeof statistic.notAllocatedAmount === 'number' ? statistic.notAllocatedAmount.toLocaleString() : '--' }}</div>
        </div>

        <div class="item" >
          <div class="title">
            <span>当年考核进度收入(万)</span>
            <a-tooltip placement="top" title="本年度进度确认且完成人员分配净额">
              <a-icon type="question-circle" style="margin-left: 5px;"/>
            </a-tooltip>
            <span class="extra">
                <CurrentYearIncome range='1' @search="onSearch" />
            </span>
          </div>
          <div
            class="value"
          >{{ typeof statistic.allocatedYearAmount === 'number' ? statistic.allocatedYearAmount.toLocaleString() : '--' }}</div>
     
        </div>
      </div>

      <Padding />

      <a-form-model layout="inline" :colon="false" :model="form" @keyup.enter.native="query">
        <a-row>
          <a-col :span="22">
            <a-form-model-item>
              <a-input
                placeholder="设计编号"
                v-model="form.designCode"
                style="width: 150px"
                list="designCodeList"
              ></a-input>
              <datalist id="designCodeList">
                <option v-for="item in designCodeList" :key="item">{{ item }}</option>
              </datalist>
            </a-form-model-item>

            <a-form-model-item>
              <a-input placeholder="合同名称" v-model="form.name" style="width: 150px"></a-input>
            </a-form-model-item>

            <a-form-model-item>
              <a-input placeholder="生产负责人" v-model="form.chargerName" style="width: 150px"></a-input>
            </a-form-model-item>

            <a-form-model-item>
              <a-select
                placeholder="责任部门"
                show-search
                :filter-option="$selectFilterOption"
                v-model="form.productionUnitId"
                style="width: 150px"
              >
                <a-select-option
                  v-for="item in productionDepartmentList"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.uniqueName
                  }}
                </a-select-option>
              </a-select>
            </a-form-model-item>


                  <a-form-model-item>
              <a-select
                placeholder="产值分配部门"
                show-search
                :filter-option="$selectFilterOption"
                v-model="form.staffProductionUnitFullId"
                style="width: 150px"
              >
                <a-select-option
                  v-for="item in organizationList"
                  :key="item.fullId"
                  :value="item.fullId"
                >
                  {{ item.uniqueName
                  }}
                </a-select-option>
              </a-select>
            </a-form-model-item>

            



            <a-form-model-item>
              <a-select style="width: 150px" v-model="form.allocateType" placeholder="确认情况">
                <a-select-option
                  v-for="item in [
                    {
                      name: '当年已全部分配',
                      value: 'progressConfigured',
                    },
                    {
                      name: '当年已确认未分配',
                      value: 'notProgressConfigured',
                    }
                  ]"
                  :key="item.value"
                  :value="item.value"
                >{{ item.name }}</a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item>
              <a-range-picker
                :placeholder="['进度确认开始时间', '进度确认结束时间']"
                v-model="form.date"
                style="width: 280px"
              />

             </a-form-model-item>

            <a-form-model-item>
              <a-range-picker
                :placeholder="['人员产值审核通过开始时间', '人员产值审核通过结束时间']"
                v-model="form.date2"
                style="width: 380px"
              />

            </a-form-model-item>

            <a-form-model-item>
              <a-space>
                <a-button @click="query" type="primary">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-model-item>
          </a-col>

          <a-col :span="2">
            <div class="right" style="margin-top: 4px;gap:8px">
              <!-- <a-button
                @click="download"
                v-if="$getPermission($route.path + '/download-income')"
              >导出收支明细</a-button>-->
              <a-button
                type="primary"
                @click="downloadProgressVisible = true"
                v-if="$getPermission($route.path + '/download-progress')"
              >导出进度明细</a-button>

              <a-modal
                title="请选择日期范围"
                :visible="downloadProgressVisible"
                :footer="null"
                @cancel="downloadProgressVisible = false"
              >
                <a-range-picker @change="onDatechange" />
              </a-modal>
            </div>
          </a-col>
        </a-row>
      </a-form-model>

      <Padding />

      <a-table
        :indentSize="0"
        :expandedRowKeys.sync="expandedRowKeys"
        bordered
        :data-source="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
                    total,
                    current,
                    pageSize,
                    showTotal: (total) => `共 ${total} 条记录`,
                  }"
        rowKey="id"
      >
        <!-- :scroll="scroll" -->
        <a-table-column title="#" width="28px">
          <template slot-scope="text">
            <!-- 子项目显示一个缩进图标 -->
            <span v-if="text.pid">
              <span
                class="iconfont icon-md-return-right"
                style="font-size: 12px; color: #999; padding-left: 4px"
              ></span>
            </span>
          </template>
        </a-table-column>

        <a-table-column title="设计编号">
          >
          <template slot-scope="text">
            <div class="between">
              <span>{{ text.designCode }}</span>

              <a-tooltip>
                <template slot="title">
                  <span>点击拆分子项目</span>
                </template>
                <a
                  v-if="text.pid && !text.projectPhase"
                    style="color: #f00"
                    href="#"
                    @click.prevent="addSonProjectSub(text)"
                  >
                  <a-icon type="plus-square" />
                </a>
                <!-- 当前项目没有子项目、不是其他项目的子项目、并且当前项目没有阶段，这样才能拆分子项目，合同审批状态为通过，才能拆(configureStatus=approved) -->
                <a
                  v-if="!hasChildren(text) &&
                                  !text.pid &&
                                  !text.projectType &&
                                  text.contract &&
                                  text.contract.configureStatus === 'approved'
                                  "
                  style="color: #f00"
                  href="#"
                  @click.prevent="addSonProject(text)"
                >
                  <a-icon type="plus-square" />
                </a>
              </a-tooltip>
            </div>
          </template>
        </a-table-column>

        <a-table-column title="合同名称">
          <template slot-scope="text">
            <span>{{ text.name }}</span>
            <a-tag
              v-if="text.contract && text.contract.type === 'compensated'"
              color="orange"
              style="margin-left: 4px; padding: 0 2px; line-height: 1.1em"
            >
              <span style="font-size: 10px">补</span>
            </a-tag>
          </template>
        </a-table-column>

        <a-table-column title="项目类型" width="100px" v-if="$getPermission($route.path + '/type')">
          <template slot-scope="text">
            <DataDictFinder dictType="projectType" :dictValue="text.projectType" />
          </template>
        </a-table-column>
        <a-table-column title="项目阶段" width="200px" v-if="$getPermission($route.path + '/type')">
          <template slot-scope="text">{{ text.projectPhaseName }}</template>
        </a-table-column>

        <!-- <a-table-column title="所属区域" width="140px">
          <template slot-scope="text">{{ text.contract ? text.contract.areaName : "" }}</template>
        </a-table-column>-->
        <a-table-column title="生产部门" width="140px">
          <template slot-scope="text">
            <div>
              {{
              text.contract ? text.contract.oriProductionUnitName : ""
              }}
            </div>
          </template>
        </a-table-column>
        <a-table-column title="责任部门负责人">
          <template slot-scope="text">
            <div>
              {{
              text.contract ? text.contract.productionUnitNameUnique : ""
              }}-{{ text.chargerName }}
            </div>
          </template>
        </a-table-column>

        <a-table-column title="调整记录" width="65px" align="center">
          <template slot-scope="text">
            <div
              style="cursor: pointer"
              v-if="Array.isArray(text.amountChangeList) &&
                          text.amountChangeList.length > 0
                          "
            >
              <a-popover placement="bottomLeft">
                <template slot="content">
                  <a-table
                    size="small"
                    bordered
                    :data-source="text.amountChangeList"
                    :pagination="false"
                    rowKey="year"
                  >
                    <a-table-column data-index="year" title="年份" align="center" width="80px"></a-table-column>
                    <a-table-column data-index="projectAmountChange" align="right" width="80px">
                      <div class="column-title" slot="title">
                        <div>合同金额</div>
                        <div>(万元)</div>
                      </div>
                      <template slot-scope="projectAmountChange">
                        <Money :money="projectAmountChange" />
                      </template>
                    </a-table-column>
                    <a-table-column data-index="subAmountSumChange" align="right" width="80px">
                      <div class="column-title" slot="title">
                        <div>分包总额</div>
                        <div>(万元)</div>
                      </div>
                      <template slot-scope="subAmountSumChange">
                        <Money :money="subAmountSumChange" />
                      </template>
                    </a-table-column>
                    <a-table-column data-index="projectPureAmountChange" align="right" width="80px">
                      <div class="column-title" slot="title">
                        <div>合同净额</div>
                        <div>(万元)</div>
                      </div>
                      <template slot-scope="projectPureAmountChange">
                        <Money :money="projectPureAmountChange" />
                      </template>
                    </a-table-column>

                    <a-table-column align="right" width="100px">
                      <div class="column-title" slot="title">
                        <div>当年已确认可分配进度</div>
                        <div>(万元)</div>
                      </div>
                      <template slot-scope="text">
                        <Money :money="text.amountChange" />
                      </template>
                    </a-table-column>
                    <a-table-column align="right" width="100px">
                      <div class="column-title" slot="title">
                        <div>当年已确认未分配</div>
                        <div>(万元)</div>
                      </div>
                      <template slot-scope="text">
                        <Money :money="text.notAllocatedAmountChange" />
                      </template>
                    </a-table-column>
                    <a-table-column align="right" width="80px" title="操作">
                      <template slot-scope="text">
                        <a
                          v-if="typeof text.notAllocatedAmountChange === 'number' &&
                                                  text.notAllocatedAmountChange !== 0 &&
                                                  $getPermission('/produce/progress/adjust-allocate')
                                                  "
                          @click.prevent="$router.push(
                                '/produce/progress/adjust-allocate?id=' +
                                text.progressId
                              )
                              "
                          href="#"
                        >产值分配</a>
                      </template>
                    </a-table-column>
                  </a-table>
                </template>
                <span>
                  <a-icon type="down-circle" />展开
                </span>
              </a-popover>
            </div>
            <div v-else>--</div>
          </template>
        </a-table-column>

        <a-table-column data-index="projectAmount" align="right" width="80px">
          <div class="column-title" slot="title">
            <div>合同金额</div>
            <div>(万元)</div>
          </div>
          <template slot-scope="projectAmount">
            <Money :money="projectAmount" />
          </template>
        </a-table-column>
        <a-table-column data-index="subSum" align="right" width="80px">
          <div class="column-title" slot="title">
            <div>分包总额</div>
            <div>(万元)</div>
          </div>
          <template slot-scope="subSum">
            <Money :money="subSum" />
          </template>
        </a-table-column>
        <a-table-column data-index="projectPureAmount" align="right" width="80px">
          <div class="column-title" slot="title">
            <div>合同净额</div>
            <div>(万元)</div>
          </div>
          <template slot-scope="projectPureAmount">
            <Money :money="projectPureAmount" />
          </template>
        </a-table-column>
        <a-table-column title="历史项目进度" data-index="lastYearProgress" align="right" width="60px">
          <template
            slot-scope="lastYearProgress"
          >{{ lastYearProgress >= 0 ? lastYearProgress + "%" : "--" }}</template>
        </a-table-column>

        <a-table-column title="累计项目进度" align="center">
          <template slot-scope="text">
            <div v-if="typeof text.progress === 'number'" class="between">
              <a-progress :percent="text.progress" :showInfo="false" />
              <span style="color: rgba(0, 0, 0, 0.45); width: 50px">{{ text.progress }}%</span>
            </div>
            <div v-else></div>
          </template>
        </a-table-column>

        <a-table-column align="right">
          <div class="column-title" slot="title">
            <div>当年已确认可分配进度</div>
            <div>(万元)</div>
          </div>
          <template slot-scope="text">
            <Money :money="text.thisYearApprovedAmount" />
          </template>
        </a-table-column>
        <a-table-column align="right">
          <div class="column-title" slot="title">
            <div>当年已确认未分配</div>
            <div>(万元)</div>
          </div>

          <template slot-scope="text">
            <a-space>
              <Money :money="text.notAllocatedAmount" />

              <PermissionButton path="allocate">
                <a-tooltip v-if="text.notAllocatedAmount > 0">
                  <template slot="title">分配</template>
                  <a
                    v-if="!hasChildren(text)"
                    style="color: #f00"
                    href="#"
                    @click.prevent="allocate(text)"
                  >
                    <span class="iconfont icon-fenpeijiaose" style="color: #1890ff"></span>
                  </a>
                </a-tooltip>
              </PermissionButton>
            </a-space>
          </template>
        </a-table-column>
        <a-table-column align="right" title="操作">
          <template slot-scope="text">
            <!-- 正常的项目或者父项目才可以配置收支 -->
            <!-- <private-button
              v-if="!text.pid"
              path="income-expenditure"
              :params="{ id: text.id }"
            >
              <span>收支管理</span>
            </private-button>-->

            <private-button
              v-if="!text.projectPhase && !hasChildren(text)"
              routeType="method"
              danger
              @clickButton="configText(text)"
              path="config"
            >
              <span>配置</span>
            </private-button>

            <private-button v-if="!hasChildren(text)" path="detail" :params="{ id: text.id }">
              <span>查看</span>
            </private-button>

            <a
              href="#"
              v-if="$getPermission($route.path + '/edit-progress')"
              @click.prevent="$router.push($route.path + '/edit-progress?id=' + text.id)
                          "
            >修改进度</a>
          </template>
        </a-table-column>
      </a-table>
    </a-card>

    <AddSonProject
      :visible="addSonProjectVisible"
      @setVisible="setSonProjectVisible"
      :project="activeProject"
    />
    <AddSonProjectSub
      :visible="addSonProjectVisibleSub"
      @setVisible="setSonProjectVisible"
      :project="activeProject"
    />
  </div>
</template>

<script>
// import screen from "@/mixins/screen";
import organization from "@/mixins/organization";
import watermark from "@/mixins/watermark";
import {
  fetchList,
  check,
  fetchDownloadFile,
  downloadProgress
} from "@/api/project";
import { mapGetters } from "vuex";
import moment from "moment";
import AddSonProject from "./add-son-project";
import AddSonProjectSub from "./add-son-project-sub";
import { saveAs } from "file-saver";

import request from "@/api/request";
import MonthList from "./month-list.vue";
import CurrentYearIncome from './current-year-income.vue'

function fetchStatistic() {
  return request({
    url: "/project-service/project/dept/sum"
  });
}

export default {
  mixins: [organization, watermark],
  // screen

  components: {
    AddSonProjectSub,
    AddSonProject,
    MonthList,
    CurrentYearIncome,
  },

  data() {
    return {
      allocateType: "",

      statistic: {},
      form: {},
      designCodeList: [],

      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,

      expandedRowKeys: [], // 展开的行

      id: null,
      name: null,
      subPackages: [], // 分配需要的分包信息，用来算技术3的成本
      visible: false, // 分配弹窗

      addSonProjectVisible: false, // 增加子项目弹窗
      addSonProjectVisibleSub: false,
      activeProject: {},

      downloadProgressVisible: false // 导出进度明细弹窗
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    contractStatusList() {
      return this.findDataDict("contractType");
    }
  },

  activated() {
    fetchStatistic().then(res => {
      this.statistic = res || {};
    });

    const { query } = this.$route;
    const { code } = query || {};
    if (code) {
      this.form = {
        designCode: code
      };
    }
    const str = window.localStorage.getItem("search-design-code");
    if (str) {
      this.designCodeList = JSON.parse(str);
    }

    this.getList();
  },

  methods: {
    onSearch(payload) {
      if (Array.isArray(payload)) {
        this.form = {};
        payload.forEach(item => {
          if (item.type === "deptId") {
            this.form = {
              ...this.form,
              productionUnitId: item.value
            };
          } else if (item.type === "date") {
            const obj = moment(item.value);
            const obj2 = moment(item.value).add(1, "months");
            this.form = {
              ...this.form,
              date: [obj, obj2]
            };
          }else if (item.type === 'year') {
              if (item.value) {
                const obj = moment(item.value);
                const obj2 = moment(item.value).add(1, "months");
                this.form = {
                  ...this.form,
                  date2: [obj, obj2]
                };
              } else {
                const obj = moment().startOf('year');
                const obj2 = moment().add(1, "year").startOf('year');
                this.form = {
                  ...this.form,
                  date2: [obj, obj2]
                };
              }
          } else if (item.type === 'staffProductionUnitFullId') {
              this.form = {
                ...this.form,
                staffProductionUnitFullId: item.value
              };
          }
        });
      } else {
        if (payload.type === "deptId") {
          this.form = {
            productionUnitId: payload.value
          };
        } else if (payload.type === "date") {
          const obj = moment(payload.value);
          const obj2 = moment(payload.value).add(1, "months");
          this.form = {
            date: [obj, obj2]
          };
        }
      }

      this.allocateType = "";
      this.current = 1;
      this.getList();
    },

    onConditionChange(key, value) {
      if (value) {
        this.allocateType = key;
      } else {
        this.allocateType = "";
      }

      if (key === "notProgressConfigured") {
        this.form = {
          allocateType: key
        };
      } else {
        this.form = {};
      }

      this.current = 1;
      this.getList();
    },

    addSonProject(text) {
      this.activeProject = text;
      this.addSonProjectVisible = true;
    },
    addSonProjectSub(text) {
      this.activeProject = text;
      this.addSonProjectVisibleSub = true;
    },
    setSonProjectVisible(value) {
      this.addSonProjectVisible = value;
      this.addSonProjectVisibleSub = value;
      // 刷新数据
      this.getList();
    },

    getList() {
      this.saveDesignCode();
      const { current, pageSize } = this;
      this.loading = true;

      let progressApprovedDateStart, progressApprovedDateEnd;
      if (Array.isArray(this.form.date) && this.form.date.length === 2) {
        progressApprovedDateStart = this.form.date[0].format("YYYY-MM-DD");
        progressApprovedDateEnd = this.form.date[1].format("YYYY-MM-DD");
      }

      let staffConfigureApprovedDateStart, staffConfigureApprovedDateEnd;
      if (Array.isArray(this.form.date2) && this.form.date2.length === 2) {
        staffConfigureApprovedDateStart = this.form.date2[0].format("YYYY-MM-DD");
        staffConfigureApprovedDateEnd = this.form.date2[1].format("YYYY-MM-DD");
      }

      fetchList({
        pageNum: current,
        pageSize: pageSize,
        ...this.form,

        date: undefined,
        progressApprovedDateStart,
        progressApprovedDateEnd,


        date2: undefined,
        staffConfigureApprovedDateStart,
        staffConfigureApprovedDateEnd,

        allocateType: this.allocateType
          ? this.allocateType
          : this.form.allocateType
      })
        .then(res => {
          if (Array.isArray(res.list)) {
            this.list = Object.freeze(
              res.list.map(item => {
                return {
                  ...item,
                  key: item.id,
                  children: Array.isArray(item.splitProjectList)
                    ? item.splitProjectList.map(element => {
                        return {
                          ...element,
                          key: element.id
                        };
                      })
                    : undefined
                };
              })
            );
            this.expandedRowKeys = this.list
              .filter(item => item.children)
              .map(item => item.key);
            this.total = res.totalSize ? res.totalSize : 0;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    // 判断当前项目是否有子项目，当前项目有了子项目，所有的按钮都不能点了，包括拆分子项目
    hasChildren(text) {
      return !!text.children;
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    query() {
      this.current = 1;
      this.form = {
        ...this.form,
        contractId: undefined
      };
      this.allocateType = "";
      this.getList();
    },
    reset() {
      this.allocateType = "";
      this.form = {};
      this.current = 1;
      this.getList();
    },

    configText(text) {
      check({
        id: text.contract.id,
        code: text.contract.code,
        productionMasterId: text.contract.productionMasterId,
        areaId: text.contract.areaId,
        subAreaId: text.contract.subAreaId,
        productionUnitId: text.contract.productionUnitId
      })
        .then(res => {
          if (res) {
            const that = this;
            this.$confirm({
              title: res + "，是否前往合同管理进行配置？",
              onOk() {
                that.$router.push(`/produce/contract?code=${text.designCode}`);
              }
            });
          } else {
            this.$router.push(`${this.$route.path}/config?id=${text.id}`);
          }
        })
        .catch(() => {});
    },

    // 打开分配弹窗
    allocate(text) {
      const { allocateCalcFormula, allocateCalcFactor } = text.contract || {};
      if (allocateCalcFormula === "2025") {
        this.$router.push(
          this.$route.path +
            "/allocate2025?id=" +
            text.id +
            "&factor=" +
            allocateCalcFactor
        );
      } else if (allocateCalcFormula === "2024") {
        this.$router.push(
          this.$route.path +
            "/allocate2024?id=" +
            text.id +
            "&factor=" +
            allocateCalcFactor
        );
      } else if (allocateCalcFormula === "2023") {
        this.$router.push(
          this.$route.path +
            "/allocate2023?id=" +
            text.id +
            "&factor=" +
            allocateCalcFactor
        );
      } else if (allocateCalcFormula === "2022") {
        this.$router.push(
          this.$route.path +
            "/allocate2022?id=" +
            text.id +
            "&factor=" +
            allocateCalcFactor
        );
      } else if (allocateCalcFormula === "2021") {
        this.$router.push(this.$route.path + "/allocate2021?id=" + text.id);
      } else {
        this.$router.push(this.$route.path + "/allocate?id=" + text.id);
      }
    },
    setVisible(visible) {
      this.visible = visible;
    },

    // 导出
    download() {
      fetchDownloadFile(this.form)
        .then(blob => {
          saveAs(blob, "项目收支明细");
        })
        .catch(err => {
          console.log("err", err);
        });
    },

    saveDesignCode() {
      if (this.form.designCode) {
        if (this.designCodeList.indexOf(this.form.designCode) === -1) {
          this.designCodeList.unshift(this.form.designCode);
          if (this.designCodeList.length > 10) {
            this.designCodeList.pop();
          }
          window.localStorage.setItem(
            "search-design-code",
            JSON.stringify(this.designCodeList)
          );
        }
      }
    },

    onDatechange(date) {
      if (Array.isArray(date) && date.length === 2) {
        downloadProgress({
          startDate: date[0].format("YYYY-MM-DD"),
          endDate: date[1].format("YYYY-MM-DD")
        }).then(blob => {
          saveAs(blob, "项目进度明细");
          this.downloadProgressVisible = false;
        });
      }
    }
  }
};
</script>
<style lang="less" scoped>
.download {
  float: right;
}

.column-title {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.primary-text {
  white-space: normal;
}

.help-text {
  font-size: 11px;
  color: rgb(138, 136, 136);
  margin-top: 2px;
}

.card {
  display: flex;

  .item {
    border: 2px solid #f0f0f0;
    margin-right: 12px;
    padding: 12px;
    border-radius: 2px;
    min-width: 240px;

    .title {
      margin-bottom: 6px;
      display: flex;
      align-items: center;
      .extra {
        margin-left: auto;
        cursor: pointer;
      }
    }

    .value {
      font-size: 2em;
      color: #1890ff;
    }
  }

  .active {
    border-color: #1890ff;
  }
}
</style>