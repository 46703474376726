<template>
  <div>
    <a-icon type="setting" class="setting" @click="visible = true" />

    <a-modal title="分院数据管理" :visible="visible" @cancel="cancel" :footer="null" width="600px">
      <div class="left">
        <div class="label" style="padding-right: 8px">年份</div>
        <a-select style="width: 160px" v-model="year">
          <a-select-option
            v-for="item in [
            {
                name: '2025',
                value: 2025,
            },
            {
                name: '2024',
                value: 2024,
            },{
                name: '2023',
                value: 2023,
            }, {
                          name: '2022',
                value: 2022,
            }, {
                          name: '2021',
                value: 2021,
            }, {
                          name: '2020',
                value: 2020,
            }]"
            :key="item.value"
            :value="item.value"
          >{{ item.name }}</a-select-option>
        </a-select>
      </div>
      <Padding />

      <a-table bordered :data-source="list" :loading="loading" :pagination="false">
        <a-table-column title="月份" align="center">
          <template slot-scope="text">{{ text.month + "月"}}</template>
        </a-table-column>

        <a-table-column title="当月计划确认(万元)" align="center">
          <template slot-scope="text">
            <a-input-number v-model="text.planAmount" size="small" />
          </template>
        </a-table-column>
        <a-table-column title="当月已确认(万元)" align="center">
          <template slot-scope="text">
            <a-input-number v-model="text.approvedAmount" size="small" />
          </template>
        </a-table-column>
      </a-table>

      <div class="right" style="padding-top: 16px">
        <a-space>
          <a-button @click="cancel">关闭</a-button>
          <a-button type="primary" :loading="saveLoading" @click="save">保存</a-button>
        </a-space>
      </div>
    </a-modal>
  </div>
</template>

<script>
import request from "@/api/request";

function fetchList(data) {
  return request({
    url: "/project-service/projectPlan/branchData/query/" + data.year,
    method: "post"
  });
}

function add(data) {
  return request({
    url: "/project-service/projectPlan/branchData",
    method: "post",
    data
  });
}
export default {
  data() {
    return {
      visible: false,
      year: 2024,
      loading: false,
      list: [],
      saveLoading: false
    };
  },

  watch: {
    visible() {
      if (this.visible) {
        this.getList();
      }
    },
    year() {
      this.getList();
    }
  },

  methods: {
    cancel() {
      this.visible = false;
    },
    getList() {
      this.loading = true;
      fetchList({
        year: this.year
      })
        .then(res => {
          if (Array.isArray(res)) {
            const list = [
              ...res.map(item => {
                return {
                  ...item,
                  key: item.id
                };
              })
            ];

            if (list.length === 12) {
              this.list = list;
              return;
            }
            for (let i = 1; i < 13; i++) {
              if (!res.find(item => item.month === i)) {
                list.push({
                  key: Math.random().toString() + i,
                  year: this.year,
                  month: i,
                  planAmount: 0,
                  approvedAmount: 0
                });
              }
            }

            list.sort((x, y) => x.month - y.month);

            this.list = list;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    save() {
      this.saveLoading = true;

      add(this.list)
        .then(res => {
          console.log("res", res);
        })
        .finally(() => {
          this.saveLoading = false;
        });
    }
  }
};
</script>